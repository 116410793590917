/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    /*
    * Mobile Menu
    */
    $('.js-menuToggle').on('click', function(e){
        e.preventDefault();
        $('.js-menuToggle').toggleClass('burger--open');
        $('.nav-primary__menu').toggleClass('nav-primary__menu--open');
        $('#js-header').toggleClass('site-head--open');
    });

    function closeMenu() {
        $('.js-menuToggle').removeClass('burger--open');
        $('.nav-primary__menu').removeClass('nav-primary__menu--open');
        $('#js-header').removeClass('site-head--open');
    }

    $(window).resize(function(){
        closeMenu();
    });

    /*
     * Lightbox
     */

    var $lightBox = $('#sa-lightbox'); // lightbox object

    if($lightBox.length) {

        // if no cookie and not front page
        if((checkCookie('sa-lightbox-visited') !== 'yes')) {

            $lightBox.removeClass('hidden'); // show lightbox
            setTimeout(function(){
                initializeSmallMap();
            },1000);


        }

        // confirm button
        $('#sa-lightbox-close').on('click', function(e) {

            $lightBox.addClass('hidden'); // hide lightbox

            setCookie('sa-lightbox-visited', 'yes', 30); // set cookie

        })

    }



    /*
    * If Map
    */
    if($('#map').length) {
        window.onload = loadScript;
    }

})(jQuery); // Fully reference jQuery after this point.


/* ========================================================================
 * Google Maps
 * ======================================================================== */


function initialize() {
  var mapOptions = {
      zoom: 16,
      center: new google.maps.LatLng(51.5353495,-0.0574509), // London
      disableDefaultUI: true,
      // Snazzy Maps.
      styles: [{"featureType":"water","stylers":[{"visibility":"on"},{"color":"#b5cbe4"}]},{"featureType":"landscape","stylers":[{"color":"#efefef"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"color":"#83a5b0"}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#bdcdd3"}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#e3eed3"}]},{"featureType":"administrative","stylers":[{"visibility":"on"},{"lightness":33}]},{"featureType":"road"},{"featureType":"poi.park","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":20}]},{},{"featureType":"road","stylers":[{"lightness":20}]}]
  };
  var mapElement = document.getElementById('map');
  var map = new google.maps.Map(mapElement, mapOptions);
  var marker = new google.maps.Marker({
      position: new google.maps.LatLng(51.5353495,-0.0574509),
      map: map,
      title: 'City Smile',
      icon: templateUrl + '/dist/images/pin.png'
  });
}

function loadScript() {
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp' +
      '&signed_in=true&callback=initialize&key=AIzaSyCiqGyYneMigy_daWK_Fm5f9KinNBT2gc0';
  document.body.appendChild(script);
}

function initializeSmallMap() {
  var mapOptions = {
      zoom: 15,
      center: new google.maps.LatLng(51.5353495,-0.0574509), // London
      disableDefaultUI: true,
      // Snazzy Maps.
      styles: [{"featureType":"water","stylers":[{"visibility":"on"},{"color":"#b5cbe4"}]},{"featureType":"landscape","stylers":[{"color":"#efefef"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"color":"#83a5b0"}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#bdcdd3"}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#e3eed3"}]},{"featureType":"administrative","stylers":[{"visibility":"on"},{"lightness":33}]},{"featureType":"road"},{"featureType":"poi.park","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":20}]},{},{"featureType":"road","stylers":[{"lightness":20}]}]
  };
  var mapElement = document.getElementById('map-lightbox');
  var map = new google.maps.Map(mapElement, mapOptions);
  var marker = new google.maps.Marker({
      position: new google.maps.LatLng(51.5353495,-0.0574509),
      map: map,
      title: 'City Smile',
      icon: templateUrl + '/dist/images/pin.png'
  });
}


/*
 * Cookies Lightbox
 */



/*
 * Check for Cookie
 * @params cookiename
 */

function checkCookie(cookieName) {
    var theCookie=" "+document.cookie;
    var ind=theCookie.indexOf(" "+cookieName+"=");
    if (ind==-1) ind=theCookie.indexOf(";"+cookieName+"=");
    if (ind==-1 || cookieName=="") return "";
    var ind1=theCookie.indexOf(";",ind+1);
    if (ind1==-1) ind1=theCookie.length;
    return unescape(theCookie.substring(ind+cookieName.length+2,ind1));
}


/*
 * Set Cookie
 * @params cookiename
 */

function setCookie(cookieName, cookieValue, nDays) {
    var today = new Date();
    var expire = new Date();
    if (nDays==null || nDays==0) nDays=1;
    expire.setTime(today.getTime() + 3600000);
    //document.cookie = cookieName + "=" + escape(cookieValue) + ";expires="+expire.toGMTString();
    document.cookie = cookieName + "=" + escape(cookieValue) + ";";
}